.footer-links a{
    text-decoration: none !important;
}

.footer-links a:hover{
    color:#4299e1;
}

.footer-links .active{
    color:#4299e1 !important;
}