.coach-img{
    min-width:  260px;
    min-height: 260px;
    max-height: 260px;
    object-fit: cover;
}

.coach-carousel {
    overflow-x: auto;
    display: flex;
    justify-content: center;
  }
  
  .coach-carousel::-webkit-scrollbar {
    display: none;
  }
  
  .coach-carousel-content {
    display: flex;
    margin-left: 20px;
    /*margin-right: 20px;*/
    background: transparent;
    width: fit-content;
  }

