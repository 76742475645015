.chakra-form__required-indicator {
  color: #2a4365 !important;
}

.bold {
  font-weight: 600 !important;
}

.same-height {
  height: 100%;
}
