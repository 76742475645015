.insta-carousel {
  overflow-x: auto;
  display: flex;
  justify-content: center;
}

.insta-carousel::-webkit-scrollbar {
  display: none;
}

.insta-carousel-content {
  display: flex;
  margin-left: 20px;
  /*margin-right: 20px;*/
  background: transparent;
  width: fit-content;
}
/* Hover on image */
.textWithBlurredBg {
  width: 260px;
  height: auto;
  display: inline-block;
  position: relative;
  transition: 0.3s;
  object-fit: cover;
}

.textWithBlurredBg img {
  transition: 0.3s;
  border-radius: 2px;
}

.insta-img {
  min-width: 260px;
  min-height: 260px;
  max-height: 260px;
  object-fit: cover;
}

.textWithBlurredBg:hover img {
  filter: blur(0.5px) brightness(80%);
}

.textWithBlurredBg :not(img) {
  position: absolute;
  z-index: 1;
  top: 40%;
  width: 100%;
  text-align: center;
  color: #fff;
  opacity: 0;
  transition: 0.3s;
}

.textWithBlurredBg:hover :not(img) {
  opacity: 1;
}
