.container {
  display: flex;
  height: 625px;
}

.user-bono-container {
  display: flex;
  max-height: 336px;
  width: 100%;
  max-width: 750px;
  margin: auto;
}

.user-bono-container-mob {
  display: flex;
  max-height: 250px;
  width: 100%;
}

.user-bono-list {
  flex: 1;
  background-color: transparent;
  overflow-y: auto;
}

.sidebar {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-top: 0;
  overflow-y: auto;
}

.search-bar {
  padding: 10px;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  background-color: white; /* Para que el fondo sea blanco y no se vea el contenido detrás */
  z-index: 10;
}

.total-bar {
  padding: 4px;
  box-sizing: border-box;
  position: sticky;
  bottom: 0px;
  justify-content: end;
  background-color: var(--chakra-colors-gray-100);
  z-index: 10;
}

.total-bar-mb {
  margin-bottom: -8px !important;
}

.user-list {
  flex: 1.25;
  background-color: transparent;
  padding: 0 10px 0px 10px;
  max-width: 300px;
  min-width: 175px;
}

.user-box {
  background-color: transparent;
  padding: 0 10px 0px 10px;
  border-bottom: 1px solid #ddd;
}

.user-box:last-child {
  border: 0px;
}

.user-box:hover {
  background-color: var(--chakra-colors-blue-50);
}

ul {
  list-style-type: none;
  padding: 0;
}

li:not(:last-child) {
  margin-bottom: 8px;
}

li:last-child {
  padding-bottom: 10px;
}

.content {
  flex: 3;
  background-color: #ffffff;
  /*padding: 10px;*/
  border: 1px solid #ddd;
  border-left: 0;
  border-top: 0;
}

.content-item {
  font-size: 18px;
  margin: auto;
}

@media (max-width: 1280px) {
  .container {
    height: 517px;
  }
}

@media (max-width: 992px) {
  .container {
    height: 444px;
  }
}

@media (max-width: 480px) {
  .container {
    height: 100px;
  }

  .user-bono-container-mob {
    max-height: 239px;
  }
}

.selected {
  background-color: #edf2f7 !important;
}

.pt0-tab-panel {
  padding-top: 0 !important;
}

.pb0-tab-panel {
  padding-bottom: 0 !important;
}

.test {
  padding-top: 2px !important;
}

div.text-overflow {
  white-space: nowrap;
  min-width: 50px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.5px;
  transition: 1s;

  @media screen and (max-width: 991px) {
    font-size: 14px !important;
  }

  /*media query entre dos*/
  @media (min-width: 1281px) {
    font-size: 18px !important;
  }
}

div.text-overflow.hover-active:hover {
  /*animation: scrollText 10s linear infinite;*/
  text-overflow: clip;
  overflow: visible;
  transform: translateX(calc(100px - 100%));
}

span.blue-text {
  color: #3182ce;
}

div.text-overflow-mobile {
  white-space: nowrap;
  min-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  font-weight: 400;
  font-size: 21px;

  @media screen and (max-width: 375px) {
    font-size: 18px !important;
  }

  /*media query entre dos*/
  @media (min-width: 480px) {
    font-size: 26px !important;
  }
}

div.text-overflow-mobile-content {
  white-space: nowrap;
  min-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px;
  text-align: left;

  @media screen and (max-width: 375px) {
    font-size: 14px !important;
  }

  /*media query entre dos*/
  @media (min-width: 480px) {
    font-size: 16px !important;
  }
}

div.text-overflow-mobile-content a {
  display: inline-flex;
}

div.text-overflow-mobile-content svg {
  margin-top: 4px;
  margin-left: 4px;
}
