.bono-carousel {
  overflow-x: auto;
  display: flex;
  justify-content: center;
}

.bono-carousel::-webkit-scrollbar {
  display: none;
}

.bono-carousel-content {
  display: flex;
  margin-left: 20px;
  /*margin-right: 20px;*/
  background: transparent;
  width: fit-content;
}

/* Hover on image */
.textWithBlurredBgBono {
  width: 260px;
  height: auto;
  display: inline-block;
  position: relative;
  transition: 0.3s;
  object-fit: cover;
}

.textWithNoBlurredBgBono {
  width: 260px;
  height: auto;
  display: inline-block;
  position: relative;
  object-fit: cover;
}

.textWithBlurredBgBono img {
  transition: 0.3s;
  border-radius: 2px;
}

.bono-img {
  width: 260px;
  height: 260px;
  object-fit: cover;
  overflow: hidden;
}

.textWithBlurredBgBono:hover img,
.bono-card:hover img {
  filter: blur(0.7px) brightness(60%);
}

.textWithBlurredBgBono :not(img) {
  position: absolute;
  z-index: 1;
  top: 40%;
  width: 100%;
  text-align: center;
  color: #fff;
  opacity: 0;
  transition: 0.3s;
  margin-left: 5px;
}

.textWithBlurredBgBono:hover :not(img),
.bono-card:hover :not(img) {
  opacity: 1;
}

.badge-color {
  color: white !important;
  background-color: #3182ce !important;
  border-radius: 0% !important;
  font-weight: 400 !important;
  width: fit-content;
  font-family: "Oswald";
}

.chakra-modal__content {
  border-radius: 0 !important;
}
