body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: white;
  font-weight: 200;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clear-fix {
  min-width: 18px;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 110px;
}

::-webkit-scrollbar {
  width: 10px;
  color: blue;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#profile-avatar div[role="img"] {
  font-size: 80px !important;
}

@media (max-width: 767px) {
  #profile-avatar div[role="img"] {
    font-size: 70px !important;
  }
}

@media (max-width: 479px) {
  #profile-avatar div[role="img"] {
    font-size: 60px !important;
  }
}

.chakra-card {
  border-radius: 0px !important;
}

.clear-fix-social {
  padding-top: 35px;
  background-color: var(--chakra-colors-gray-800) !important;
}

.mb0-tab-panel{
  margin-bottom: 0px !important;
}