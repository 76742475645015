@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');

@font-face {
  font-family: Gotham;
  src: url('../../static/Gotham-Font/Gotham-Black.otf');
}

@font-face {
  font-family: 'Brutal Type';
  src: local('Brutal Type Regular'), local('Brutal-Type-Regular'),
      url('../../static/BrutalType/BrutalType.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.slider-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 70px;
  background-attachment: fixed;
  font-family: 'Oswald', sans-serif;
  position: relative;
  width: 100vw;
  height: 100vh;
}

.slide1{
  background-image: url("../../images/portrait/move_it-min.webp");
}

.slide2{
  background-image: url("../../images/portrait/phone_app-min.webp");
}

@media only screen and (max-width: 1400px) {
  .slide3{
    background-position: bottom 500px;
  }
}

@media only screen and (max-width: 650px) {
  .cuc-section{
    background-attachment: scroll;
  }
}

.slider-section img {
  padding-top: 60px;
  margin: auto;
}

.slider-section-btn{
  font-family: Oswald;
  letter-spacing: 1px;
}

.title-fade-in {
	-webkit-animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.5s both;
	        animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.5s both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Swiper */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide .background-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.move-it-lettering{
    -webkit-text-stroke: 1.5px white;
    color: transparent; 
    font-weight: 500 !important;
}