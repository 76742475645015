.justify {
  justify-content: center !important;
}

.day-btn {
  font-weight: 500;
  justify-content: center;
  height: 70px;
  width: 90px;
  gap: 2px !important;
}

.day-btn:hover{
  background-color: var(--chakra-colors-gray-200);
  cursor: pointer;
  -webkit-user-select: none !important; /* Safari */
  -ms-user-select: none !important; /* IE 10 and IE 11 */
  user-select: none !important; /* Standard syntax */
}

.selected-day {
  background-color: var(--chakra-colors-gray-800) !important;
  color: white !important;
}

.active {
  color: blue !important;
}

.event-transition {
  -webkit-animation: fade-in 0.75s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
  animation: fade-in 0.75ss cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
