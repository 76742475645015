/* Customizing full calendar's buttons */
button[title="Today"], button[title="Previous day"], button[title="Next day"], button[title="This week"], button[title="Previous week"], button[title="Next week"]{
    height: var(--chakra-sizes-10) !important;
    min-width: var(--chakra-sizes-10) !important;
    font-size: var(--chakra-fontSizes-md) !important;
    -webkit-padding-start: var(--chakra-space-3) !important;
    padding-inline-start: var(--chakra-space-3) !important;
    -webkit-padding-end: var(--chakra-space-3) !important;
    padding-inline-end: var(--chakra-space-3) !important;
    background: var(--chakra-colors-blue-400) !important;
    color: var(--chakra-colors-white) !important;
    border: 0 !important;
    font-family: Oswald !important;
    letter-spacing: 1px !important;
    font-weight: 100 !important;
}

button[title="Today"], button[title="This week"]{
    width: 60px !important;
}

button[title="Today"]:hover, button[title="Previous day"]:hover, button[title="Next day"]:hover, button[title="This week"]:hover, button[title="Previous week"]:hover, button[title="Next week"]:hover{
    background: var(--chakra-colors-blue-600) !important;
}

button[title="Today"]:disabled, button[title="This week"]:disabled {
    background: var(--chakra-colors-blue-400) !important;
    cursor: default !important;
}

span.fc-icon.fc-icon-chevron-right, span.fc-icon.fc-icon-chevron-left {
    font-size: 1em !important;
    margin-top: -2px !important;
}

/* Título calendario */
h2.fc-toolbar-title {
    font-size: 24px !important;
}

@media (max-width: 430px) {
    h2.fc-toolbar-title {
        font-size: 17px !important;
    }

    button[title="Today"], button[title="Previous day"], button[title="Next day"], button[title="This week"], button[title="Previous week"], button[title="Next week"]{
        height: var(--chakra-sizes-8) !important;
        min-width: var(--chakra-sizes-8) !important;
        font-size: var(--chakra-fontSizes-sm) !important;
    }

    button[title="Today"], button[title="This week"]{
        width: 45px !important;
    }
}


@media (max-width: 355px) {
    h2.fc-toolbar-title {
        font-size: 14px !important;
        padding-top: 2px !important;
    }

    button[title="Today"], button[title="Previous day"], button[title="Next day"], button[title="This week"], button[title="Previous week"], button[title="Next week"]{
        height: var(--chakra-sizes-7) !important;
        min-width: var(--chakra-sizes-7) !important;
        font-size: var(--chakra-fontSizes-xs) !important;
    }
}

/* Border radius del modal */
section.chakra-modal__content.css-fxczwc {
    border-radius: 0 !important;
}

header.chakra-modal__header css-9fgtzh {
    padding-bottom: 0 !important;
}

.fc-timegrid-event{
    border-radius: 0 !important;
}

.fc-direction-ltr .fc-timegrid-col-events{
    margin: 0px 1px 0px 1px!important;
}