@media screen and (max-width: 550px) {
  #chart-height > div:first-child {
    min-height: 400px;
  }
}

@media screen and (min-width: 550px) {
  #chart-height > div:first-child {
    min-height: 570px;
  }
}
