.prevent-select {
    -webkit-user-select: none !important; /* Safari */
    -ms-user-select: none !important; /* IE 10 and IE 11 */
    user-select: none !important; /* Standard syntax */
}

.allow-select {
  -webkit-user-select: text !important; /* Safari */
  -ms-user-select: text !important; /* IE 10 and IE 11 */
  user-select: text !important; /* Standard syntax */
}

.bono-purchased-img{
    width:  110px;
    height: 110px;
    object-fit: cover;
    overflow: hidden;
}

.chakra-popover__content{
  border-radius: 0 !important;
}

/*MOVE TO ACTIVIDAD (mis-bonos)*/
.p0-tab-panel{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pb0-tab-panel{
  padding-bottom: 0 !important;
}

.icon-padding{
  margin-left: 4px;
  margin-top: 2px;
}
