.scale-logo:hover {
  transform: scale(1.2);
}

.my-collapse{
  position: absolute;
  padding-top: 32px;
  z-index: 2;
  background: white;
}

.menu-fade-in {
	-webkit-animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.oswald-font{
  font-family: 'Oswald', sans-serif;
  letter-spacing: 1px;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

input{
  border-radius: 0 !important;
}

button{
  border-radius: 0 !important;
}

.invert{
  filter: invert(100%);
}